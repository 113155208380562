import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router'

import Link from 'next/link'

import {
  Pane,
  Heading,
  Button,

  Menu,
  Popover,
  Paragraph,
  Position,
  Dialog,
} from 'evergreen-ui';

import {
  MenuIcon,
} from 'evergreen-ui';
import { Cross1Icon, HamburgerMenuIcon } from '@radix-ui/react-icons'

const Routes = require("../utils/routes");

export default function Header() {

  let links = [
    { label: "Home", path: Routes.links.index },
    { label: "Solutions", path: Routes.links.solutions },
    { label: "Product", path: Routes.links.product.page },
    { label: "Pricing", path: Routes.links.pricing },
    { label: "Developers", path: Routes.links.developers },
    { label: "Changelog", path: Routes.links.updates.changelog },
  ]

  const [show, setShow] = useState(false);

  function MenuPrompt(props) {
    return (
      <Pane className="" >
        <Dialog
          isShown={show}
          title="Demo Video"
          intent="danger"
          onCloseComplete={() => setShow(false)}
          hasHeader={false}
          hasFooter={false}
          width={"100%"}
          height="400px"
          className="menu-dialog"
          preventBodyScrolling={true}>
          <Pane className="p-3" >

            <Pane className="row w-100 mx-auto pb-3">
              <Pane className="col-6 mt-3 p-0">
                <Link href="/">
                  <Pane className="col text-left d-flex justify-content-start p-0 align-items-center ">
                    <img src="/logo.svg" className="" height="30px" width="30px" />
                    <Heading className="pointed ml-2 hero-text text-">Learnir</Heading>
                  </Pane>
                </Link>
              </Pane>
              <Pane className="col-6 mt-3 p-0 text-right">
                <Cross1Icon className="menu-icon" onClick={() => setShow(false)} />
              </Pane>
            </Pane>

            {links.map((route, index) => {
              if (route.external) {
                return (
                  <a key={index} href={route.path} role="button">
                    <Pane size={500} className="mt-4">
                      <Paragraph> {route.label}</Paragraph>
                    </Pane>
                  </a>
                )
              } else {
                return (
                  <Link key={index} href={route.path} role="button" >
                    <Pane size={500} className="mt-4">
                      <Paragraph> {route.label}</Paragraph>
                    </Pane>
                  </Link>
                )
              }

            })}
          </Pane>
          <Pane className="row w-100 mx-auto pb-3 p-1">
            <Pane className="col-12 mt-3">
              <a href={Routes.links.external.console.login}>
                <Button className="w-100 text-black" padding={20}> Login </Button>
              </a>
            </Pane>
            <Pane className="col-12 mt-3">
              <a href={Routes.links.external.console.signup} className="w-100 bg-brand text-white">
                <Button className="w-100 bg-brand text-white" padding={20}> Signup </Button>
              </a>
            </Pane>
          </Pane>
        </Dialog>
      </Pane>
    )
  }

  return (
    <Pane className="w-100 p-2 header" >

      {MenuPrompt()}

      <Pane className="container align-items-center">
        <Pane className="row align-items-center">

          <Pane className="col text-left d-flex justify-content-start pl-0 align-items-center ">
            <img src="/logo.svg" className="" height="30px" width="30px" />
            <Link href="/">
              <Heading className="pointed ml-2 hero-text text-">
                Learnir
              </Heading>
            </Link>
          </Pane>

          <Pane className="col d-none d-lg-flex d-xl-flex align-items-center pr-0 justify-content-end" >
            {
              links.map((route, index) => {
                return (
                  <span className="mr-5" key={index}>
                    <Link href={route.path}><Heading size={300} className="pointed cursor text-"><a>{route.label}</a></Heading></Link>
                  </span>
                )
              })
            }
            <a href={Routes.links.external.console.login}><Button className="mr-3 text-dark about-page-text-dark"> Login </Button></a>
            <a href={Routes.links.external.console.signup}><Button className="ml-2 bg-brand text-white"> Start </Button></a>
          </Pane>

          <Pane className="col d-lg-none d-sm-flex d-md-flex align-items-end text-end text-right" >
            <HamburgerMenuIcon className="menu-icon" onClick={() => setShow(true)} />
          </Pane>

        </Pane>
      </Pane>
    </Pane>
  )
}
