import Head from 'next/head'
import { useRouter } from 'next/router'

import Link from 'next/link'
import styled from 'styled-components'

import {
  Pane,
  Heading,
  Paragraph
} from 'evergreen-ui';

import {
  ArrowRightIcon
} from 'evergreen-ui';

import { TwitterLogoIcon, LinkedInLogoIcon } from '@radix-ui/react-icons'

const Routes = require("../utils/routes");
const utils = require("../utils/utils");

export default function Footer() {

  return (
    <Pane className="w-100">
      <footer className="w-100 mx-auto container">
        <Pane className="row align-items-top" >
          <Pane className="col-lg-4 col-md-12 col-sm-12 pt-5" >
            <Heading size={400} className="pointed cursor">Learnir</Heading>
            <Paragraph className="mt-3 d-flex align-items-center" size={300}>
              <a href={Routes.links.external.social.twitter} className="d-flex align-items-center">
                <TwitterLogoIcon className="mr-2 text-brand" /> Twitter
              </a>
            </Paragraph>
            <Paragraph className="mt-3 d-flex align-items-center" size={300}>
              <a href={Routes.links.external.social.linkedin} className="d-flex align-items-center">
                <LinkedInLogoIcon className="mr-2 text-brand" /> Linkedin
              </a>
            </Paragraph>
          </Pane>

          <Pane className="col-lg-2 col-md-12 col-sm-12 pt-5" >
            <Heading size={400} className="pointed cursor">Product</Heading>
            <Paragraph className="mt-3" size={300}><Link href={Routes.links.updates.changelog}>Changelog</Link></Paragraph>
            <Paragraph className="mt-3" size={300}><Link href={Routes.links.documents.security}>Security</Link></Paragraph>
            <Paragraph className="mt-3" size={300}><Link href={Routes.links.pricing}>Pricing</Link></Paragraph>
            <Paragraph className="mt-3" size={300}><Link href={Routes.links.documents.guide}>Guide</Link></Paragraph>
          </Pane>

          <Pane className="col-lg-2 col-md-12 col-sm-12 pt-5" >
            <Heading size={400} className="pointed cursor">Resources</Heading>
            <Paragraph className="mt-3" size={300}><a href="mailto:support@learnir.co">Support</a></Paragraph>
            <Paragraph className="mt-3" size={300}><a href="mailto:team@learnir.co" className="">Contact</a></Paragraph>
            <Paragraph className="mt-3" size={300}><a href={Routes.links.external.github.page}>Github</a></Paragraph>
          </Pane>

          <Pane className="col-lg-2 col-md-12 col-sm-12 pt-5" >
            <Heading size={400} className="pointed cursor">Solutions</Heading>
            <Paragraph className="mt-3" size={300}><Link href={Routes.links.product.templates}>Templates</Link></Paragraph>
            <Paragraph className="mt-3" size={300}><Link href={Routes.links.product.components}>Components</Link></Paragraph>
            <Paragraph className="mt-3" size={300}><Link href={Routes.links.product.sdks}>SDKs</Link></Paragraph>
          </Pane>

          <Pane className="col-lg-2 col-md-12 col-sm-12 pt-5" >
            <Heading size={400} className="pointed cursor">Company</Heading>
            <Paragraph className="mt-3" size={300}><Link href={Routes.links.company.page}>About</Link></Paragraph>
            <Paragraph className="mt-3" size={300}><Link href={Routes.links.company.careers}>Careers</Link></Paragraph>
            <Paragraph className="mt-3" size={300}><Link href={Routes.links.company.blog}>Blog</Link></Paragraph>
          </Pane>
        </Pane>
        <Pane className="row align-items-top pt-5" >
          <Pane className="col-lg-6 col-md-12 col-sm-12" >
            <Paragraph className="mt-3" size={300}> © Learnir 2022 </Paragraph>
          </Pane>

          <Pane className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-sm-start justify-content-md-start justify-content-lg-end" >
            <Pane className="row align-items-top" >
              <Paragraph className="mt-3 col-6" size={300}><Link href={Routes.links.documents.terms}>Terms</Link></Paragraph>
              <Paragraph className="mt-3 col-6" size={300}><Link href={Routes.links.documents.privacy}>Privacy</Link></Paragraph>
            </Pane>
          </Pane>
        </Pane>
      </footer>
    </Pane>
  )
}