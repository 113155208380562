let links = {
    index: '/',
    pricing: '/pricing',
    developers: "/developers",
    solutions: "/solutions",
    minds: "/blog",
    blog: "/blog",

    features: '/#features',
    kn_focused: "/about",
    foundations: "/about#foundations",
    inspiration: "/solutions#inspiration",

    documents: {
        page: "/documents",
        terms: "/documents/terms",
        privacy: "/documents/privacy",
        security: "/documents/security",
        guide: "/documents/guide",
    },
    updates: {
        page: "/updates",
        changelog: "/updates/#changelog",
        releases: "/updates/#releases",
    },
    product: {
        page: "/product",
        templates: "/product/#templates",
        components: "/product/#components",
        sdks: "/product/#sdks",
    },
    company: {
        page: '/about',
        careers: "/about/#careers",
        blog: '/blog',
    },
    external:{
        console: {
            index: "https://console.learnir.co",
            login: "https://console.learnir.co/login",
            signup: "https://console.learnir.co/signup",
            build: "https://console.learnir.co/build",
            components: "https://console.learnir.co/components",
        },
        social: {
            twitter: "https://twitter.com/learnir_co",
            linkedin: "https://linkedin.com/company/Learnir",
        },
        templates: {
            repo: "https://github.com/Learnir/learnir-portals",
            portal_simple: "https://portal.learnir.co",
        },
        github:{
            page: "https://github.com/learnir",
            sdks: "https://github.com/learnir/learnir-sdks",
            templates: "https://github.com/Learnir/learnir-portals",
            components: "https://github.com/Learnir/learnir-exp-components",
        },
        portal: "https://portal.learnir.co",

        api_documentation: "https://documenter.getpostman.com/view/17708778/UUy37RGV",
        build: "https://documenter.getpostman.com/view/17708778/UUy37RGV",
        github_starter: "https://github.com/learnir",
        port_example_api: "Get https://api.learnir.co/v1/content/port/:key",
        integrations_page: "https://www.rudderstack.com/integration/",
    }
}

module.exports = { links }