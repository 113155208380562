import toast, { Toaster } from 'react-hot-toast';


function notify(state, message) {
    switch (state) {
        case 1:
            toast.success(message, {
                style: {
                    fontFamily: "Segoe UI",
                    borderRadius: "4px",
                    color: "#234361",
                    fontWeight: 600,
                    fontSize: "14px",
                    maxWidth: "100%"
                }
            });
            break;
        case 2:
            toast.error(message, {
                icon: '⚠️',
                iconTheme: {
                    primary: '#DE9246',
                    secondary: '#fff',
                },
                style: {
                    fontFamily: "Segoe UI",
                    borderRadius: "4px",
                    color: "#234361",
                    fontWeight: 600,
                    fontSize: "14px",
                    maxWidth: "100%"
                }
            });
            break;
        case 3:
            toast.error(message, {
                style: {
                    fontFamily: "Segoe UI",
                    borderRadius: "4px",
                    color: "#234361",
                    fontWeight: 600,
                    fontSize: "14px",
                    maxWidth: "100%"
                }
            });
            break;
        default:
            toast(message, {
                style: {
                    fontFamily: "Segoe UI",
                    borderRadius: "4px",
                    color: "#234361",
                    fontWeight: 600,
                    fontSize: "14px",
                    maxWidth: "100%"
                }
            });
    }
}

function support(data) {
    let base = document.getElementById("Smallchat");
    let iframe = base.firstChild;
    // pops up support request
    if (isBrowser() && iframe) {
        let elmnt = iframe.contentWindow.document.getElementsByClassName("Launcher")[0];
        elmnt && elmnt.click();
    }
};

function isEmail(email){
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(mailformat);
}

function slugify(str){
    if(typeof str == "string"){
        return str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
    }
}

let functions = {
    support,
    notify,
    isEmail,
    slugify
}

export { functions };